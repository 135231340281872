.main-container {
    position: relative;
    top: 0;
    z-index: 10;
    background: white;
    padding-bottom: 0.4rem;
    border: none;
}

.container {
    width: 1000px;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Nerko One', cursive;
    font-family: 'Ubuntu', sans-serif;
    box-shadow: 0 0 10px 1px rgb(51, 49, 49);
}

#firstPage {
    /* z-index: 1; */
    background: white;
}

p {
    color: #515151;
}

.img1 {
    width: 250px;
    height: 250px;
    margin: -60px 0 0 65px;
}

.name {
    margin: 200px 0 0 0;
    font-size: 28px;
    font-weight: bold;
}

#name-devon {
    font-size: 70px;
}

.contact-section,
.skills-section {
    margin: 50px 0 0 80px;
}

.marks-dots {
    display: flex;
    justify-content: space-between;
}

h2 {
    margin-bottom: 20px;
}

h4 {
    margin-bottom: 20px;
}

.dots {
    margin-bottom: 10px;
}

.right-side {
    margin: 0px 80px;
}

.this {
    margin: 0 0 45px 0;
}

.personal-details {
    margin-left: 20px;
}

#bottom-image-1 {
    margin-top: -635px;
    margin-left: 928px;
    height: 706px;
    width: 60px;
}

#top-image-1 {
    width: 500px;
    margin-top: -100px;
    margin-left: -48px;
}

#image-shap2-1 {
    margin-top: -290px;
    margin-left: -5px;
    height: 400px;
    width: 500px;
}